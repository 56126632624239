import React, { useRef } from "react";
import {
    Container,
    Typography,
    Paper,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Link,
} from "@mui/material";

const PrivacyPolicyMobile = () => {

    const sectionRefs = {
        welcome: useRef(null),
        informationWeCollect: useRef(null),
        permissionsWeUse: useRef(null),
        howWeUseYourInformation: useRef(null),
        thirdPartyServices: useRef(null),
        dataSecurity: useRef(null),
        userChoicesControl: useRef(null),
        childrensPrivacy: useRef(null),
        changesToThisPrivacyPolicy: useRef(null),
        contactUs: useRef(null),
    };

    // Function to scroll to a specific section
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <Box
            sx={{
                backgroundColor: "white",
                minHeight: "100vh",
            }}>
            <Container
                maxWidth="lg"
                sx={{ display: "flex", my: 4, marginRight: 200 }}>
                {/* Sidebar */}
                <Paper
                    elevation={0}
                    sx={{
                        width: "250px",
                        p: 2,
                        borderRadius: 2,
                        mr: 3,
                        position: "sticky",
                        top: "20px",
                        alignSelf: "flex-start",
                        backgroundColor: "white",
                        borderRight: "1px solid #e0e0e0",
                        height: "80vh",
                        overflowY: "auto", // Make sidebar scrollable
                    }}>
                    <List>
                        {Object.keys(sectionRefs).map((key) => (
                            <ListItem key={key} disablePadding>
                                <ListItemButton
                                    onClick={() => scrollToSection(sectionRefs[key])}
                                    sx={{
                                        borderRadius: "4px",
                                        "&:hover": {
                                            backgroundColor: "#f1f3f4",
                                        },
                                    }}>
                                    <ListItemText
                                        primary={key
                                            .replace(/([A-Z])/g, " $1")
                                            .replace(/^./, (str) => str.toUpperCase())
                                            .trim()}
                                        primaryTypographyProps={{
                                            color: "#5f6368",
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Paper>

                {/* Main Content */}
                <Box sx={{ flex: 1 }}>
                    <Paper
                        elevation={0}
                        sx={{ p: 4, borderRadius: 2, backgroundColor: "white" }}>
                        {/* Welcome Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.welcome}>
                            <Typography
                                variant="h3"
                                component="h1"
                                gutterBottom
                                sx={{
                                    fontWeight: "normal",
                                    color: "#202124",
                                }}>
                                Privacy Policy
                            </Typography>

                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                Welcome to Rayal Motor Calculation . Your privacy is important to us, and we are
                                committed to protecting your personal information. This Privacy
                                Policy explains how we collect, use, and share information when
                                you use our mobile application Rayal Motor Calculation .
                            </Typography>
                        </Box>

                        {/* Information We Collect Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.informationWeCollect}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                1. Information We Collect
                            </Typography>
                            <Typography
                                variant="h6"
                                component="h3"
                                gutterBottom
                                sx={{
                                    color: "#202124",
                                }}>
                                Personal Information
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                We may collect the following personal information:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        <strong>SMS Data</strong> – We access and process SMS messages to provide core app functionalities such as OTP verification, automated message processing,
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        <strong>Device Information</strong> We may collect device-specific information like device ID, operating system, and app version to improve app performance.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        <strong>Usage Data</strong> We may collect information about how you interact with our app, including feature usage and error reports.
                                    </Typography>
                                </li>
                            </ul>

                        </Box>

                        {/* Permissions We Use Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.permissionsWeUse}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                2. Permissions We Use and Why
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                Our app uses the following permissions:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        <strong>Internet Access</strong> – To access the internet
                                        for features like updates and data sync.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        <strong>Network State Access</strong> – To check network
                                        connectivity.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        <strong>Sms permissions</strong> – To read Sms OTP verification, automated message processing
                                    </Typography>
                                </li>

                            </ul>
                        </Box>

                        {/* How We Use Your Information Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.howWeUseYourInformation}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                3. How We Use Your Information
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                We use the collected information to:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        Improve app functionality and user experience.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        Provide location-based features.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        Enable multimedia and file storage capabilities.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        Ensure seamless app performance and updates.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>

                        {/* Third-Party Services Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.thirdPartyServices}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                4. Third-Party Services
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                Our app may use third-party services for analytics, advertising,
                                or other functionalities. These services have their own privacy
                                policies, and we encourage you to review them.
                            </Typography>
                        </Box>

                        {/* Data Security Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.dataSecurity}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                5. Data Security
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                We take reasonable measures to protect your information from
                                unauthorized access, alteration, disclosure, or destruction.
                                However, no method of transmission over the internet is 100%
                                secure.
                            </Typography>
                        </Box>

                        {/* User Choices & Control Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.userChoicesControl}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                6. User Choices & Control
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                You have the right to:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        Revoke permissions at any time through your device settings.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#202124",
                                        }}>
                                        Request deletion of your personal data by contacting us.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>

                        {/* Children's Privacy Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.childrensPrivacy}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                7. Children's Privacy
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                Our app is not intended for children under 13 years of age. If
                                we discover that a child has provided personal information, we
                                will delete it immediately.
                            </Typography>
                        </Box>

                        {/* Changes to This Privacy Policy Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.changesToThisPrivacyPolicy}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                8. Changes to This Privacy Policy
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                We may update this Privacy Policy from time to time. Any changes
                                will be posted within the app. We encourage you to review this
                                page periodically for updates.
                            </Typography>
                        </Box>

                        {/* Contact Us Section */}
                        <Box sx={{ my: 3 }} ref={sectionRefs.contactUs}>
                            <Typography
                                variant="h5"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    color: "#202124",
                                }}>
                                9. Contact Us
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                If you have any questions about this Privacy Policy, please
                                contact us at:
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ color: "#202124" }}>
                                📧 Email:{" "}
                                <Link
                                    href="mailto:[Your Contact Email]"
                                    sx={{ color: "#1a73e8" }}>
                                    rayalbrokers@gmail.com
                                </Link>
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
};

export default PrivacyPolicyMobile;
