import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FloatLabel from "../../UiComponents/FloatLabel/FloatLabel";
import { Formik, Form, Field } from "formik";
import {
  downloadFileByKey,
  PostBranch,
  updateBankDetailsByUserId,
  UpdateBranch,
} from "../../Service/_index";
import CloseIcon from "@mui/icons-material/Close";
import { FileUploader } from "react-drag-drop-files";
import Button from "@mui/material/Button";
import { AWS_DIRECTORY_NAME } from "../../Shared/CommonConstant";
import { DownloadIcon } from "../../Resources/Icons/icons";
import { Tooltip } from "antd";
import Loader from "../../UiComponents/Loader/Loader";
import "./User.scss";

const AddBankDrawer = (props) => {
  const {
    id,
    selectedBankData,
    formType,
    setOpenAddBankDrawer,
    title,
    GetActiveData,
    newValue,
  } = props;
  let formRef = useRef();

  const initialValues = {
    bankAccountHolderName: "",
    accountNumber: "",
    bankName: "",
    bankBranch: "",
    ifscCode: "",
    micrNumber: "",
    panNumber: "",
    aadharNumber: "",
    gstNumber: "",
    tds: "",
    PANCardProof: "",
    PassBookProof: "",
    AadharProof: "",
    GSTProof: "",
  };
  const UserId = sessionStorage.getItem("UserId");
  const [openLoader, setOpenLoader] = useState(false);

  useEffect(() => {
    if (formType === "edit") {
      Object.keys(initialValues).forEach((el) => {
        initialValues[el] = selectedBankData.values[el];
      });
    }
    formRef.setFieldValue(initialValues);
  }, []);

  const downloadFile = (key) => {
    downloadFileByKey(key).then((res) => {
      // console.log("res : ", res);
      const downloadURL = res?.data?.downloadURL || "";
      window.open(downloadURL);
    });
  };

  const onSubmit = (e) => {
    let index = selectedBankData.index;
    // newValue({ index, e });
    // console.log("e ", e);
    const selectedRow = selectedBankData.values;
    const updatedData = {};
    Object.keys(e).forEach((key) => {
      if (selectedRow && e[key] != selectedRow[key]) {
        updatedData[key] = e[key];
      } else {
        updatedData[key] = e[key];
      }
    });
    // console.log("index : ", index);
    let formData = new FormData();
    for (var key in updatedData) {
      formData.append(key, updatedData[key]);
    }
    if (updatedData[AWS_DIRECTORY_NAME.AWS__PAN_CARD_DIRECTORY_NAME]) {
      formData.append(
        AWS_DIRECTORY_NAME.AWS__PAN_CARD_DIRECTORY_NAME,
        updatedData[AWS_DIRECTORY_NAME.AWS__PAN_CARD_DIRECTORY_NAME]
      );
    }
    if (updatedData[AWS_DIRECTORY_NAME.AWS_BANK_BOOK_DIRECTORY_NAME]) {
      formData.append(
        AWS_DIRECTORY_NAME.AWS_BANK_BOOK_DIRECTORY_NAME,
        updatedData[AWS_DIRECTORY_NAME.AWS_BANK_BOOK_DIRECTORY_NAME]
      );
    }
    if (updatedData[AWS_DIRECTORY_NAME.AWS_AADHAR_PROOF_DIRECTORY_NAME]) {
      formData.append(
        AWS_DIRECTORY_NAME.AWS_AADHAR_PROOF_DIRECTORY_NAME,
        updatedData[AWS_DIRECTORY_NAME.AWS_AADHAR_PROOF_DIRECTORY_NAME]
      );
    }
    // return true;
    // console.log("updatedData ", updatedData);
    if (updatedData) {
      formData.append("index", index);
      setOpenLoader(true);
      updateBankDetailsByUserId(id, formData)
        .then((res) => {
          // console.log("res ", res);
          const bankDetails = res?.data?.bankDetails || [];
          index = index != "undefined" ? bankDetails.length - 1 : index;
          const updatedData = bankDetails[index];
          console.log("updatedData ", updatedData);
          newValue({ index: index, e: updatedData });
          setOpenAddBankDrawer(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setOpenLoader(false);
        });
    } else {
      setOpenAddBankDrawer(false);
    }
  };

  console.log(selectedBankData);
  return (
    <>
      <div>
        <Grid container className="DrawerHeader">
          <Grid item xs={6} sm={6}>
            <Typography>{title} Bank Detail</Typography>
          </Grid>
          <Grid item xs={6} sm={6} className="d-flex justify-content-end">
            <CloseIcon
              onClick={() => setOpenAddBankDrawer(false)}
              sx={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          innerRef={(ref) => {
            if (ref) {
              formRef = ref;
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="container pb-3">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Account Holder Name"
                      value={values.bankAccountHolderName}
                    >
                      <Field
                        name="bankAccountHolderName"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Account Number"
                      value={values.accountNumber}
                    >
                      <Field
                        name="accountNumber"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="Bank Name" value={values.bankName}>
                      <Field
                        name="bankName"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="Bank Branch" value={values.bankBranch}>
                      <Field name="bankBranch" className="InputFiled" />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="Ifsc Code" value={values.ifscCode}>
                      <Field
                        name="ifscCode"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="Micr Number" value={values.micrNumber}>
                      <Field
                        name="micrNumber"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>{" "}
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="Pan Number" value={values.panNumber}>
                      <Field
                        name="panNumber"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>{" "}
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Adhar Number"
                      value={values.aadharNumber}
                    >
                      <Field
                        name="aadharNumber"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>{" "}
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="GST Number" value={values.gstNumber}>
                      <Field
                        name="gstNumber"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel label="TDS Percentage" value={values.tds}>
                      <Field
                        name="tds"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="File-Upload"
                    style={{
                      display: values.PANCardProof ? "flex" : "block",
                      "align-items": "center",
                    }}
                  >
                    <FileUploader
                      handleChange={(e) => setFieldValue("PANCardProof", e)}
                      name="PANCardProof"
                      label={
                        values?.PANCardProof?.key
                          ? "PAN Card uploaded, Upload another one?"
                          : "Upload Your PAN Card"
                      }
                      dropMessageStyle={{
                        color: "red",
                        border: "none",
                        borderRadius: "0px",
                      }}
                    />
                    <Tooltip title="download">
                      <DownloadIcon
                        sx={{
                          display: values.PANCardProof ? "block" : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadFile(values.PANCardProof.key)}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="File-Upload"
                    style={{
                      display: values.PassBookProof ? "flex" : "block",
                      "align-items": "center",
                    }}
                  >
                    <FileUploader
                      handleChange={(e) => setFieldValue("PassBookProof", e)}
                      name="PassBookProof"
                      label={
                        values?.PassBookProof?.key
                          ? "Passbook uploaded, Upload another one?"
                          : "Upload Your Passbook / Cheque"
                      }
                      dropMessageStyle={{
                        color: "red",
                        border: "none",
                        borderRadius: "0px",
                      }}
                    />
                    <Tooltip title="download">
                      <DownloadIcon
                        sx={{
                          display: values?.PassBookProof?.key
                            ? "block"
                            : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadFile(values.PassBookProof.key)}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="File-Upload"
                    style={{
                      display: values.AadharProof ? "flex" : "block",
                      "align-items": "center",
                    }}
                  >
                    <FileUploader
                      handleChange={(e) => setFieldValue("AadharProof", e)}
                      name="AadharProof"
                      label={
                        values?.AadharProof?.key
                          ? "Aadhar uploaded, Upload another one?"
                          : "Upload Your Aadhar"
                      }
                      dropMessageStyle={{
                        color: "red",
                        border: "none",
                        borderRadius: "0px",
                      }}
                    />
                    <Tooltip title="download">
                      <DownloadIcon
                        sx={{
                          display: values?.AadharProof?.key ? "block" : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadFile(values.AadharProof.key)}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="File-Upload"
                    style={{
                      display: values.GSTProof ? "flex" : "block",
                      "align-items": "center",
                    }}
                  >
                    <FileUploader
                      handleChange={(e) => setFieldValue("GSTProof", e)}
                      name="GSTProof"
                      label={
                        values?.GSTProof?.key
                          ? "GST uploaded, Upload another one?"
                          : "Upload Your GST"
                      }
                      dropMessageStyle={{
                        color: "red",
                        border: "none",
                        borderRadius: "0px",
                      }}
                    />
                    <Tooltip title="download">
                      <DownloadIcon
                        sx={{
                          display: values?.GSTProof?.key ? "block" : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadFile(values.GSTProof.key)}
                      />
                    </Tooltip>
                  </Grid>
                  {/* <Grid className="Dialog_Footer" item xs={12} sm={6} gap={1}>
                    <button onClick={() => setOpenAddBankDrawer(false)}>
                      Cancel
                    </button>
                    <button type="submit" >
                      
                    </button>
                  </Grid> */}
                </Grid>
              </div>
              <div className="Dialog_Footer">
                <Button
                  onClick={() => setOpenAddBankDrawer(false)}
                  className="Dialog_Cancel"
                >
                  Cancel
                </Button>
                <Button className="Dialog_Save_Exit" type="submit">
                  {selectedBankData?.values ? "Update" : "Add New Bank"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Loader open={openLoader} />
      </div>
    </>
  );
};

export default AddBankDrawer;
