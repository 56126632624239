import axios from "../AxiosInterCeptor/AxiosInterCeptor";
import { ToastError, ToastSuccess } from "../UiComponents/Toaster/Toast";

const URL = process.env.React_App_BaseUrl;

const PostCreatePolicy = (recData) => {
  return axios.post(`${URL}/createpolicy`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

// Changes by Arun
const retreiveDataFromPolicy = (_id) => {
  // return axios.get(`${URL}/retrievePolicy/${_id}`).then((response) => {
  return axios
    .get(`${URL}/readPolicyFileByPolicyId/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    });
};

// Added by Arun
const getPolicyBazaarTicketNumber = (id) => {
  return axios
    .get(`${URL}/getPolicyBazaarTicketNumber/${id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      let message = error?.response?.data || "";
      // console.error("error ----", message);
      if (message)
        ToastError(
          typeof message == "object" ? JSON.stringify(message) : message
        );
    });
};

// loginPortal added by gokul....
const LoginPortalData = (recData) => {
  return axios
    .get(`${URL}/getLoginPortal`, {
      headers: {
        companyUserId: recData.userId,
        companyId: recData.companyId,
        isActive: recData.isActive ? true : false,
      },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    });
};

const PostSearchPolicy = (recData) => {
  return axios
    .post(`${URL}/searchpolicy`, recData)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// changes by somesh
const GetFilterCCEntry = async (recData) => {
  try {
    const response = await axios.post(`${URL}/filterCCEntry`, recData);
    const res = response.data;
    if (res.error) {
      console.log(res.error);
      return null;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const uploadCCEntryData = async (recData) => {
  try {
    const response = await axios.post(`${URL}/uploadCCEntryData`, recData);
    const res = response.data;
    if (res.error) {
      console.log(res.error);
      return null;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    ToastError(error?.response?.data?.message);
    return null;
  }
};

// changes by somesh
const unlinkTicketNumber = async (_id, recData) => {
  try {
    const response = await axios.post(
      `${URL}/unlinkTicketNumber/${_id}`,
      recData
    );
    const res = response.data;
    if (res.error) {
      ToastError(res.message);
      return null;
    } else {
      ToastSuccess(res.message);
      // console.log(res)
      return res;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// changes by somesh
const GetFilterCCEntryAll = async () => {
  try {
    const response = await axios.get(`${URL}/filterCCEntryAll`);
    const res = response.data;
    console.log(res);
    if (res.error) {
      console.log(res.error);
      return null;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// changes by somesh
const ticketAlreadyExist = async (_id, recData) => {
  try {
    const response = await axios.patch(
      `${URL}/ticketAlreadyExist/${_id}`,
      recData
    );
    const res = response.data;
    console.log(res);
    if (res.error) {
      console.log(res.error);
      return null;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// added by gokul..
const CheckTheTickNum = async (tickNum) => {
  try {
    const result = await axios.get(`${URL}/ticketAlreadyExists/${tickNum}`);
    const res = result.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};

const GetPolicyList = (data) => {
  return axios
    .get(`${URL}/createpolicy`, {
      headers: {
        logStatus: data?.logStatus,
        chequeStatus: data?.chequeStatus,
      },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const UpdatePolicyList = (_id, recData) => {
  // console.log("id : ", _id);
  // console.log("ticketNumber : ", recData);
  return axios.put(`${URL}/updatepolicy/${_id}`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      ToastError(res.message);
    } else {
      ToastSuccess(res.message);
      return res;
    }
  });
};

const DeletePolicyList = (_id) => {
  return axios
    .delete(`${URL}/deletepolicy/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        ToastError(res.message);
      } else {
        ToastError(res.message);
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const FilterPolicyList = (recData) => {
  return axios.post(`${URL}/filterPolicyList`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

const AutoFillPolicyDetails = (recData) => {
  return axios.post(`${URL}/verifypolicy`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

const ReadPolicyFileByPolicyId = (_id) => {
  return axios
    .get(`${URL}/readPolicyFileByPolicyId/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const SaveComissionReceivableAmount = (_id, recData) => {
  return axios
    .put(`${URL}/saveComissionReceivableAmount/${_id}`, recData)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    });
};

const GetCommisionReceivableAmount = (_id) => {
  return axios
    .get(`${URL}/getComissionReceivableAmount/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const GetPolicyFindbyId = (_id) => {
  return axios
    .get(`${URL}/getPolicyById/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const VerifyPolicyNumber = (data) => {
  return axios
    .get(`${URL}/verifyPolicyNumber`, {
      headers: {
        policyNumber: data,
      },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    });
};

const GetUserpayablePercentage = (_id) => {
  return axios
    .get(`${URL}/getUserpayablePercentage/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const GetBranchpayablePercentage = (_id) => {
  return axios
    .get(`${URL}/getBranchpayablePercentage/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const GetReceivablePayablePercentage = (_id) => {
  return axios
    .get(`${URL}/getReceivablePercentage/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const GetPolicyFileById = (_id) => {
  return axios
    .get(`${URL}/getPolicyFileById/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

//policy maping Api

const CreatePolicyMappingService = (recData) => {
  return axios.put(`${URL}/createPolicyMapping`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

const GetPolicyMapping = (data, id) => {
  return axios
    .get(`${URL}/getPolicyMapping`, {
      headers: { requesttype: data, mappinguserid: id },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    });
};

const GetPolicyMappingByPolicyID = (_id) => {
  return axios
    .get(`${URL}/getPolicyMappingByPolicyID/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    });
};

const UpdatePolicyMapping = (data) => {
  return axios.patch(`${URL}/updatePolicyMapping`, data).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

const ReframeEndDateTimeFormat = (endDate) => {
  let tempEndDate = new Date(endDate);
  let reframeDate = new Date(
    tempEndDate.setHours(23, 59, 59, 59)
    // tempEndDate.getMonth(),
    // tempEndDate.getDate(),
    // 23,
    // 59,
    // 59
  );
  return reframeDate;
};

const formatNumber = (num) => new Intl.NumberFormat("en-IN").format(num || 0);

export {
  PostSearchPolicy,
  LoginPortalData, //added by gokul..
  GetPolicyList,
  PostCreatePolicy,
  UpdatePolicyList,
  DeletePolicyList,
  FilterPolicyList,
  AutoFillPolicyDetails,
  SaveComissionReceivableAmount,
  GetCommisionReceivableAmount,
  GetPolicyFindbyId,
  VerifyPolicyNumber,
  GetUserpayablePercentage,
  GetBranchpayablePercentage,
  GetReceivablePayablePercentage,
  GetPolicyFileById,
  GetFilterCCEntry,
  unlinkTicketNumber,
  GetFilterCCEntryAll,
  ticketAlreadyExist,
  CheckTheTickNum,
  CreatePolicyMappingService,
  GetPolicyMapping,
  GetPolicyMappingByPolicyID,
  UpdatePolicyMapping,
  ReadPolicyFileByPolicyId,
  ReframeEndDateTimeFormat,
  getPolicyBazaarTicketNumber,
  retreiveDataFromPolicy,
  formatNumber,
  uploadCCEntryData,
};
