import axios from "../AxiosInterCeptor/AxiosInterCeptor";
import { ToastError, ToastSuccess } from "../UiComponents/Toaster/Toast";

const URL = process.env.React_App_BaseUrl;

const GetUser = (data) => {
  return axios
    .get(`${URL}/user`, {
      headers: { requesttype: data.requesttype, isAscending: data.isAscending },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const GetLogin = () => {
  return axios
    .get(`${URL}/getlogin`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const PostUser = (recData) => {
  return axios.post(`${URL}/user`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      ToastError(res.message);
    } else {
      ToastSuccess(res.message);
      return res;
    }
  });
};

// Changes by Arun
const saveWalletPassword = (recData) => {
  return axios.post(`${URL}/saveWalletPassword`, recData).then((response) => {
    const res = response.data;
    if (res.error) {
      ToastError(res.message);
    } else {
      // ToastSuccess(res.message);
      return res;
    }
  });
};

// Changes by Arun
const updateWalletPasswordByUserId = (recData) => {
  return axios
    .patch(`${URL}/updateWalletPasswordByUserId`, recData)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        ToastError(res.message);
      } else {
        return res;
      }
    });
};

const UpdateUser = (_id, recData) => {
  return axios
    .put(`${URL}/user/${_id}`, recData)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        ToastError(res.message);
      } else {
        ToastSuccess(res.message);
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateBankDetailsByUserId = (_id, recData) => {
  return axios
    .put(`${URL}/updateBankDetailsByUserId/${_id}`, recData)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        ToastError(res.message);
      } else {
        ToastSuccess(res.message);
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const downloadFileByKey = (key) => {
  return axios
    .get(`${URL}/downloadFileByKey`, {
      headers: {
        key: key,
      },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        ToastError(res.message);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const downloadFileBase64ByKey = (key) => {
  return axios
    .get(`${URL}/downloadFileBase64ByKey`, {
      headers: {
        key: key,
      },
    })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        ToastError(res.message);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const GetUserFindbyId = (_id) => {
  return axios
    .get(`${URL}/userById/${_id}`)
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

//common getFileFromAWSS3BucketByKey

const GetFileFromAWSS3BucketByKey = (data) => {
  return axios
    .get(`${URL}/getFileFromAWSS3BucketByKey`, { headers: { key: data } })
    .then((response) => {
      const res = response.data;
      if (res.error) {
        console.log(res.error);
      } else {
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// written by gokul
const verifyMobileNumber = (data) => {
  return axios.get(`${URL}/verifyMobileNumber/${data}`).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

const verifyEmailAddress = (data) => {
  return axios.get(`${URL}/verifyEmailAddress/${data}`).then((response) => {
    const res = response.data;
    if (res.error) {
      console.log(res.error);
    } else {
      return res;
    }
  });
};

// Changes by Arun
const base64toBlob = (base64Data, contentType) => {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export {
  PostUser,
  GetUser,
  GetLogin,
  UpdateUser,
  GetUserFindbyId,
  GetFileFromAWSS3BucketByKey,
  verifyMobileNumber,
  verifyEmailAddress,
  saveWalletPassword,
  updateWalletPasswordByUserId,
  updateBankDetailsByUserId,
  downloadFileByKey,
  downloadFileBase64ByKey,
  base64toBlob,
};
