import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FloatLabel from "../../../UiComponents/FloatLabel/FloatLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "antd";
import {
  GetSubBookingCode,
  GetBookingReport,
  formatNumber,
  ReframeEndDateTimeFormat,
  GetMasterReport,
  downloadBookingReport,
} from "../../../Service/_index";
import "./index.scss";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs";
import Loader from "../../../UiComponents/Loader/Loader";
const { RangePicker } = DatePicker;

const BookingScoreOption = [
  { label: "Default", value: true },
  { label: "Custom", value: false },
];

const BookingReport = () => {
  const [selectedScore, setSelectedScore] = useState();
  const [bookingCodeDetails, setBookingCodeDetails] = useState([]);
  const [bookingReportData, setBookingReportData] = useState();
  const [isShowRangePicker, setIsShowRangePicker] = useState(true);
  const [filterBy, setFilterBy] = useState({});
  const [openLoader, setOpenLoader] = useState(false);

  // Changed by Arun
  const subBookingCodeUserHeaders = [
    { header: "Approve Date", key: "approveDate" },
    { header: "Issue Date", key: "formateIssueDate" },
    {
      header: "User Name",
      key: "userName",
    },
    { header: "Booking Code", key: "bookingCode" },
    { header: "Sub Booking Code", key: "subBookingCode" },
    { header: "Company Name", key: "company" },
    { header: "Product", key: "product" },
    { header: "Sub Product", key: "subProduct" },
    { header: "Policy Type", key: "policyType" },
    { header: "Policy Number", key: "policyNumber" },
    { header: "Login Id", key: "loginID" },
    {
      header: "Customer Name",
      key: "customerName",
    },
    {
      header: "Mobile",
      key: "mobileNumber",
    },
    {
      header: "Email",
      key: "email",
    },
    {
      header: "RTO",
      key: "rtoId",
    },
    {
      header: "Reg Number",
      key: "registrationNumber",
    },
    {
      header: "Vechicle Make",
      key: "vehicleMake",
    },
    {
      header: "Model",
      key: "model",
    },
    {
      header: "Reg Year",
      key: "registrationYear",
    },

    {
      header: "GVW",
      key: "gvw",
    },
    {
      header: "CC",
      key: "cc",
    },
    {
      header: "Seat Capacity",
      key: "seatingCapacity",
    },
    {
      header: "Fuel Type",
      key: "fuelType",
    },
    {
      header: "PA Cover",
      key: "paCover",
    },
    {
      header: "OD Discount",
      key: "odDisc",
    },
    {
      header: "OD Premium",
      key: "odPremium",
    },
    {
      header: "TP Premium",
      key: "tpPremium",
    },
    {
      header: "Net Premium",
      key: "netPremium",
    },
    {
      header: "Total Premium",
      key: "totalPremium",
    },
    {
      header: "OD %",
      key: "recODPer",
    },
    {
      header: "OD Amt",
      key: "recODAmount",
    },
    {
      header: "TP %",
      key: "recTPPer",
    },
    {
      header: "TP Amt",
      key: "recTPAmount",
    },
    {
      header: "Net %",
      key: "recNetPer",
    },
    {
      header: "Net Amt",
      key: "recNetAmount",
    },
    {
      header: "Total Receivable Amount",
      key: "totalReceivableAmount",
    },
  ];

  const GetBookingCodeDetails = () => {
    GetSubBookingCode({ isAscending: true }).then((res) => {
      let FilteredData = [];
      res.data.map((e) => {
        if (e.isEnabled) {
          FilteredData.push({
            ...e,
            label: e.bookingCode + " - " + e.subBookingCode,
            value: e._id,
          });
        }
      });
      setBookingCodeDetails(FilteredData);
    });
  };

  //   Changes by Arun
  const getBookingReport = async (isDownloadExcel) => {
    if (filterBy.selectedEndDate) {
      filterBy.selectedEndDate = await ReframeEndDateTimeFormat(
        filterBy.selectedEndDate
      );
    }
    const reqBody = {
      ...filterBy,
      requestType: selectedScore ? "DEFAULT" : "CUSTOM",
    };
    setOpenLoader(true);
    if (isDownloadExcel) {
      downloadBookingReport(reqBody)
        .then((res) => {
          const FormatedData = res?.data ? res.data : [];
          FormatedData.map((element) => {
            let loginId = element.loginId;
            let lastStr = loginId.split("-").pop();
            element.loginID = lastStr
              ? loginId.replace("-" + lastStr, "")
              : loginId;
          });

          let wb = new ExcelJS.Workbook();
          let workbookName = "Booking Report.xlsx";
          let worksheetName = "Booking Report";
          let ws = wb.addWorksheet(worksheetName, {
            properties: {
              tabColor: { argb: "FFFF0000" },
            },
          });

          ws.columns = subBookingCodeUserHeaders;
          console.log("ws.columns  ", ws.columns);
          ws.addRows(FormatedData);

          ws.eachRow((row, rowNumber) => {
            row.eachCell({ includeEmpty: true }, (cell) => {
              // Set default border style for all cells (including empty cells)
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          });

          wb.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              workbookName
            );
          });
        })
        .finally(() => {
          setOpenLoader(false);
        });
    } else {
      GetBookingReport(reqBody)
        .then((res) => {
          setBookingReportData(res.data);
        })
        .finally(() => {
          setOpenLoader(false);
        });
    }
  };

  useEffect(() => {
    GetBookingCodeDetails();
  }, []);

  return (
    <>
      <Grid container spacing={1} sx={{ padding: "20px 10px 0 10px" }}>
        <Grid item sm={3} xs={12}>
          <FloatLabel label="Select Filter Type" value={selectedScore}>
            <Autocomplete
              disablePortal
              className="AutoComplete_InputBox"
              id="combo-box-demo"
              options={BookingScoreOption}
              clearIcon={false}
              onChange={(option, value) => {
                setSelectedScore(value.value);
                if (value.value) {
                  setIsShowRangePicker(true);
                } else {
                  setIsShowRangePicker(false);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </FloatLabel>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FloatLabel
            label="Select Booking code & Sub Booking Code"
            value={filterBy.bookingCode}
          >
            <Autocomplete
              disablePortal
              className="AutoComplete_InputBox"
              id="combo-box-demo"
              options={bookingCodeDetails}
              onChange={(event, option) => {
                if (option) {
                  setFilterBy({
                    ...filterBy,
                    bookingCode: option.bookingCodeId,
                    subBookingCode: option._id,
                  });
                } else {
                  setFilterBy({
                    ...filterBy,
                    bookingCode: null,
                    subBookingCode: null,
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) => option._id === value._id}
            />
          </FloatLabel>
        </Grid>
        <Grid
          item
          sm={3}
          xs={12}
          className="datePicker"
          sx={{ display: isShowRangePicker ? "none" : "block" }}
        >
          <FloatLabel label="Start date & End Date" value="react">
            <RangePicker
              placement="bottomLeft"
              className="textField w-100"
              style={{ borderRadius: "0px" }}
              format="DD/MM/YYYY"
              onChange={(dates) => {
                if (dates && dates[0] && dates[1]) {
                  setFilterBy({
                    ...filterBy,
                    selectedStartDate: dayjs(dates[0]),
                    selectedEndDate: dayjs(dates[1]),
                  });
                }
              }}
            />
          </FloatLabel>
        </Grid>
        <Grid item sm={3} xs={12} sx={{ display: "flex" }} gap={1}>
          <Button
            className="Common_Button"
            sx={{ width: { xs: "100%", sm: "40%" } }}
            onClick={() => getBookingReport(false)}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }} mt={2}>
        <Grid container className="Master_Header_Container" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography className="Master_Header_Heading">
              Booking Report
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <div className="d-flex PageContainer">
        <Paper className="container-fluid TableBox">
          <Grid
            container
            sx={{ padding: "80px 60px 80px 60px", width: "100%" }}
          >
            <Grid item xs={12} sm={3} className="Super_Container">
              <Paper
                sx={{ textAlign: "center" }}
                className="Super_Paper Paper_1"
              >
                {/* Changes by Arun */}
                <Typography className="Header_Heading">Today</Typography>
                <Typography className="Header_Count">
                  {formatNumber(bookingReportData?.today?.policyCount)} Policy
                  Count
                </Typography>
                <Typography className="Box_Od">
                  OD - ( {formatNumber(bookingReportData?.today?.odTotal)} )
                </Typography>
                <Typography className="Box_Tp">
                  TP - ( {formatNumber(bookingReportData?.today?.tpTotal)} )
                </Typography>
                <Typography className="Box_Net">
                  Net - ( {formatNumber(bookingReportData?.today?.netTotal)} )
                </Typography>
                <Typography className="Box_Total">
                  Total - ( {formatNumber(bookingReportData?.today?.total)} )
                </Typography>
                <Button
                  className="Box_Button"
                  onClick={() => getBookingReport(true)}
                >
                  Download
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} className="Super_Container">
              <Paper
                sx={{ textAlign: "center" }}
                className="Super_Paper Paper_2"
              >
                {/* Changes by Arun */}
                <Typography className="Header_Heading">Month</Typography>
                <Typography className="Header_Count">
                  {formatNumber(bookingReportData?.monthly?.policyCount)} Policy
                  Count
                </Typography>
                <Typography className="Box_Od">
                  OD - ( {formatNumber(bookingReportData?.monthly?.odTotal)} )
                </Typography>
                <Typography className="Box_Tp">
                  TP - ( {formatNumber(bookingReportData?.monthly?.tpTotal)} )
                </Typography>
                <Typography className="Box_Net">
                  Net - ( {formatNumber(bookingReportData?.monthly?.netTotal)} )
                </Typography>
                <Typography className="Box_Total">
                  Total - ( {formatNumber(bookingReportData?.monthly?.total)} )
                </Typography>
                <Button
                  onClick={() => getBookingReport(true)}
                  className="Box_Button"
                >
                  Download
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} className="Super_Container">
              <Paper
                sx={{ textAlign: "center" }}
                className="Super_Paper Paper_3"
              >
                {/* Changes by Arun */}
                <Typography className="Header_Heading">
                  Selected Date Range
                </Typography>
                <Typography className="Header_Count">
                  {formatNumber(bookingReportData?.total?.policyCount)} Policy
                  Count
                </Typography>
                <Typography className="Box_Od">
                  OD - ( {formatNumber(bookingReportData?.total?.odTotal)} )
                </Typography>
                <Typography className="Box_Tp">
                  TP - ( {formatNumber(bookingReportData?.total?.tpTotal)} )
                </Typography>
                <Typography className="Box_Net">
                  Net - ( {formatNumber(bookingReportData?.total?.netTotal)} )
                </Typography>
                <Typography className="Box_Total">
                  Total - ( {formatNumber(bookingReportData?.total?.total)} )
                </Typography>
                <Button
                  className="Box_Button"
                  onClick={() => getBookingReport(true)}
                >
                  Download
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} className="Super_Container">
              <Paper
                sx={{ textAlign: "center" }}
                className="Super_Paper Paper_4"
              >
                {/* Changes by Arun */}
                <Typography className="Header_Heading">Receivable</Typography>
                <Typography className="Header_Count">
                  {formatNumber(bookingReportData?.receivable?.policyCount)}{" "}
                  Policy Count
                </Typography>
                <Typography className="Box_Od">
                  Total Receivable - ({" "}
                  {formatNumber(bookingReportData?.receivable?.totalReceivable)}{" "}
                  )
                </Typography>
                <Typography className="Box_Tp">
                  Total Received - ({" "}
                  {formatNumber(bookingReportData?.receivable?.totalReceived)} )
                </Typography>
                <Typography className="Box_Net">
                  Pending - ({" "}
                  {formatNumber(bookingReportData?.receivable?.pending)} )
                </Typography>
                <Typography className="Box_Total">-</Typography>
                {/* <Button className="Box_Button">View</Button> */}
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        <Loader open={openLoader} />
      </div>
    </>
  );
};
export default BookingReport;
