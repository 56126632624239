import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FloatLabel from "../../UiComponents/FloatLabel/FloatLabel";
import { Formik, Form, Field } from "formik";
import {
  PostFuelType,
  GetBankDetailsByUserId,
  GetMasterCompany,
  PaidWallet,
  downloadFileByKey,
  downloadFileBase64ByKey,
  base64toBlob,
  updateWalletById,
} from "../../Service/_index";
import { ToastError, ToastSuccess } from "../../UiComponents/Toaster/Toast";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Paper } from "@mui/material";
import {
  CloseIcon,
  DeleteIcon,
  CloudOffIcon,
  EditIcon,
  DownloadIcon,
  RemoveRedEyeIcon,
} from "../../Resources/Icons/icons";

const PaidTransaction = (props) => {
  const UserId = sessionStorage.getItem("UserId");

  const {
    selectedData,
    GetData,
    setOpenPaidTransaction,
    GetUserWalletDetails,
    PaidUserName,
    functionType,
    setDrawerWidth,
    callBackFunction,
  } = props;
  let formRef = useRef();

  const initialValues = {
    walletCompanyId: "",
    amount: "",
    TDS: "",
    transactionDate: new Date(),
    TDSamount: "",
    TransferAmount: "",
    accountNumber: "",
    bankName: "",
    // remark: "",
    panNumber: "",
  };

  const [companyDetails, setCompanyDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [withTDS, setWithTDS] = useState(false);
  const [amountVal, setAmountVal] = useState(null);
  const [tdsAmount, setTdsAmount] = useState(null);
  const [showBankDetail, setShowBankDetail] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [walletCompany, setWalletCompany] = useState(null);

  // Added by Arun
  const [selectedTransactionDate, setSelectedTransactionDate] = useState(
    selectedData?.transactionDate
      ? dayjs(selectedData?.transactionDate)
      : dayjs()
  );
  const [selectedAccHolderName, setSelectedAccHolderName] = useState(null);
  const onSubmit = async (e) => {
    e.isUserPayable = true;
    // const RandomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
    // Changes by Arun
    const RandomNumber = await getRandomNumberUsingTimestamp();
    e.userId = selectedData.userId;
    e.policyNumber = RandomNumber.toString();
    e.paymentMode = functionType === "Paid" ? "PAID" : "RECEIVED";
    e.createdBy = UserId;
    e.withTDS = withTDS; //Changes by Arun
    if (selectedData?._id) {
      updateWalletById(selectedData._id, e)
        .then((res) => {
          setOpenPaidTransaction(false);
          callBackFunction();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      PaidWallet(e)
        .then((res) => {
          setOpenPaidTransaction(false);
          GetData();
          GetUserWalletDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Function to generate a random number using a timestamp as a seed
  const getRandomNumberUsingTimestamp = () => {
    const timestamp = new Date().getTime(); // Get current timestamp
    // const seed = timestamp % 10000; // Ensure seed is within a reasonable range
    // const randomNumber = Math.round(seed * Math.random()); // Generate random number using seed
    return timestamp;
  };

  const GetCompanyDetails = () => {
    GetMasterCompany().then((res) => {
      const modifiedCompanyDetails = res.data
        .map((e) => {
          if (e.isEnabled) {
            return {
              ...e,
              label: e.masterCompanyName,
              value: e._id,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);
      setCompanyDetails(modifiedCompanyDetails);
    });
  };

  const GetBankDetails = () => {
    GetBankDetailsByUserId(selectedData?.userId).then((res) => {
      const ModifiedData = res.data.bankDetails.map((e) => {
        return {
          ...e,
          label: e.bankAccountHolderName,
        };
      });
      setBankDetails(ModifiedData);
    });
  };
  React.useEffect(() => {
    GetCompanyDetails();
    GetBankDetails();
    if (selectedData._id) {
      let selectedBankDetail = {};
      const accountNumber = selectedData?.accountNumber;
      formRef.setFieldValue("bankName", selectedData?.bankName);
      if (accountNumber) {
        let bankDetails = selectedData.bankDetails;
        selectedBankDetail = bankDetails.find(
          (ele) => accountNumber == ele.accountNumber
        );
        AccountHandleFunction(selectedBankDetail);
        setSelectedAccHolderName(selectedBankDetail.bankAccountHolderName);
      }
      console.log("selectedData : ", selectedData);
      console.log("selectedBankDetail : ", selectedBankDetail);
      setWithTDS(selectedData.withTDS);
      console.log("companyDetails ::::: ", companyDetails);
      setWalletCompany(selectedData?.walletCompany);
      formRef.setFieldValue(
        "transactionDate",
        dayjs(selectedData.transactionDate)
      );
      formRef.setFieldValue("TDS", selectedData?.TDS);
      formRef.setFieldValue("TDSamount", selectedData?.TDSamount);
      formRef.setFieldValue("amount", selectedData?.amount);
      formRef.setFieldValue("remark", selectedData?.remark);
      formRef.setFieldValue("walletCompanyId", selectedData?.walletCompanyId);
      // AmountCalculateFunction("Amount", selectedData?.amount);
      editAmountCalculation(selectedData?.amount, selectedData?.TDS);
      // TDSCalculateFunction("TDS", selectedData?.TDS);
    }
  }, []);

  const downloadFile = (key) => {
    if (!key) {
      ToastError("No records found!");
    } else {
      downloadFileByKey(key).then((res) => {
        // console.log("res : ", res);
        const downloadURL = res?.data?.downloadURL || "";
        window.open(downloadURL);
      });
    }
  };

  const viewFile = (key) => {
    if (!key) {
      ToastError("No records found!");
    } else {
      downloadFileBase64ByKey(key).then((res) => {
        console.log("res : ", res);
        const downloadURL = res?.data?.downloadURL || "";
        const blobData = base64toBlob(downloadURL, "image/png");
        const blobUrl = URL.createObjectURL(blobData);
        const pdfWindow = window.open("", "_blank");
        pdfWindow?.document?.write(
          `<embed src="${blobUrl}" width="100%" height="100%" />`
        );
      });
    }
  };

  const AccountHandleFunction = (v) => {
    formRef.setFieldValue("accountNumber", v?.accountNumber);
    formRef.setFieldValue("bankName", v?.bankName);
    formRef.setFieldValue("panNumber", v?.panNumber);
    formRef.setFieldValue("TDS", v?.tds);
    setTdsAmount(v?.tds);
    setSelectedBank(v);
    setSelectedAccHolderName(v?.bankAccountHolderName);
  };

  const AmountCalculateFunction = (key, val) => {
    setAmountVal(val);
    // Changes by Arun (Round the value)
    const TDSamount = Math.round((val / 100) * tdsAmount);
    if (tdsAmount) {
      formRef.setFieldValue("TDSamount", TDSamount);
    } else {
      formRef.setFieldValue("TDSamount", val);
    }
    formRef.setFieldValue("TransferAmount", Math.round(val - TDSamount));
  };

  const editAmountCalculation = (amount, tds) => {
    setAmountVal(amount);
    // Changes by Arun (Round the value)
    const TDSamount = Math.round((amount / 100) * tds);
    if (tds) {
      formRef.setFieldValue("TDSamount", TDSamount);
    } else {
      formRef.setFieldValue("TDSamount", amount);
    }
    formRef.setFieldValue("TransferAmount", Math.round(amount - TDSamount));
  };

  const TDSCalculateFunction = (key, val) => {
    setTdsAmount(val);
    // Changes by Arun (Round the value)
    const TDSamount = Math.round((amountVal / 100) * val);
    if (amountVal) {
      formRef.setFieldValue("TDSamount", TDSamount);
    } else {
      formRef.setFieldValue("TDSamount", 0);
    }
    formRef.setFieldValue("TransferAmount", Math.round(amountVal - TDSamount));
  };

  return (
    <>
      <div className="MainRenderinContainer">
        <Grid container className="DrawerHeader" sx={{ padding: "10px" }}>
          <Grid item xs={10} sm={10}>
            <Typography>
              {functionType} to {PaidUserName}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} className="d-flex justify-content-end">
            <CloseIcon
              onClick={() => {
                setOpenPaidTransaction(false);
                setDrawerWidth("25%");
                setShowBankDetail(false);
              }}
              sx={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <div className="container-fluid">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            innerRef={(ref) => {
              if (ref) {
                formRef = ref;
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: functionType === "Paid" ? "flex" : "none",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {" "}
                          <Button
                            onClick={() => setWithTDS(true)}
                            style={{
                              background: withTDS ? "green" : "gray",
                              padding: "4px 10px",
                              color: "white",
                            }}
                            sx={{ borderRadius: "0px" }}
                          >
                            With TDS
                          </Button>
                          <Button
                            onClick={() => setWithTDS(false)}
                            style={{
                              background: withTDS ? "gray" : "green",
                              padding: "4px 10px",
                              color: "white",
                            }}
                            sx={{ borderRadius: "0px" }}
                          >
                            Without TDS
                          </Button>
                        </div>
                        <Button
                          onClick={() => {
                            setDrawerWidth(showBankDetail ? "25%" : "60%");
                            setShowBankDetail(!showBankDetail);
                          }}
                          style={{
                            background: showBankDetail ? "green" : "gray",
                            padding: "4px 10px",
                            color: "white",
                          }}
                          sx={{
                            borderRadius: "0px",
                            display: selectedBank ? "block" : "none",
                          }}
                        >
                          Bank Details
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={showBankDetail ? 4 : 12} mt={2}>
                        <FloatLabel label="Transaction Date" value="React">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              name="transactionDate"
                              className="Date_Picker w-100"
                              format="DD/MM/YYYY"
                              value={selectedTransactionDate}
                              onChange={(e) =>
                                setFieldValue("transactionDate", e.$d)
                              }
                              defaultValue={dayjs()}
                            />
                          </LocalizationProvider>
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        mt={showBankDetail ? 2 : 0}
                        sx={{ display: withTDS ? "block" : "none" }}
                      >
                        <FloatLabel
                          label="Account Holder"
                          value={values.accountNumber}
                        >
                          <Autocomplete
                            className="AutoComplete_InputBox w-100"
                            name="accountNumber"
                            disablePortal
                            id="combo-box-demo"
                            onChange={(e, v) => AccountHandleFunction(v)}
                            options={bankDetails}
                            value={selectedAccHolderName}
                            clearIcon={false}
                            renderInput={(params) => <TextField {...params} />}
                            isOptionEqualToValue={(option, value) =>
                              option.accountNumber === value.accountNumber
                            }
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        sx={{
                          display:
                            withTDS || functionType === "Received"
                              ? "block"
                              : "none",
                        }}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel label="Bank" value={values.bankName}>
                          <Field
                            name="bankName"
                            className="InputFiled"
                            style={{ textTransform: "uppercase" }}
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        sx={{ display: withTDS ? "block" : "none" }}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel label="Pan Number" value={values.panNumber}>
                          <Field
                            name="panNumber"
                            className="InputFiled"
                            style={{ textTransform: "uppercase" }}
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel label="remark" value={values.remark}>
                          <Field
                            name="remark"
                            className="InputFiled"
                            style={{ textTransform: "uppercase" }}
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel
                          label="Select Company"
                          value={values?.walletCompanyId}
                        >
                          <Autocomplete
                            className="AutoComplete_InputBox w-100"
                            name="walletCompanyId"
                            disablePortal
                            id="combo-box-demo"
                            onChange={(e, v) => {
                              console.log("v::::::::", v);
                              setFieldValue("walletCompanyId", v?._id);
                              setWalletCompany(v.masterCompanyName);
                            }}
                            value={walletCompany}
                            options={companyDetails}
                            clearIcon={false}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel label="Amount" value={values.amount}>
                          <TextField
                            name="amount"
                            value={values.amount}
                            className="InputFiled"
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) => {
                              AmountCalculateFunction("Amount", e.target.value);
                              setFieldValue("amount", e.target.value);
                            }}
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        sx={{ display: withTDS ? "block" : "none" }}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel label="TDS" value={values.TDS}>
                          <TextField
                            name="TDS"
                            className="InputFiled"
                            value={values.TDS}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) => {
                              TDSCalculateFunction("TDS", e.target.value);
                              setFieldValue("TDS", e.target.value);
                            }}
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        sx={{ display: withTDS ? "block" : "none" }}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel label="TDS Amount" value={values.TDSamount}>
                          <Field
                            name="TDSamount"
                            className="InputFiled"
                            style={{ textTransform: "uppercase" }}
                            disabled
                          />
                        </FloatLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={showBankDetail ? 4 : 12}
                        sx={{ display: withTDS ? "block" : "none" }}
                        mt={showBankDetail ? 2 : 0}
                      >
                        <FloatLabel
                          label="Transfer Amount"
                          value={values.TransferAmount}
                        >
                          <Field
                            name="TransferAmount"
                            className="InputFiled"
                            style={{ textTransform: "uppercase" }}
                            disabled
                          />
                        </FloatLabel>
                      </Grid>

                      <Grid
                        className="DrawerFooter"
                        item
                        xs={12}
                        sm={12}
                        gap={1}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            setDrawerWidth("25%");
                            setShowBankDetail(false);
                            setOpenPaidTransaction(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button type="submit">
                          {selectedData?._id ? "Update" : "Confirm"}
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {showBankDetail ? (
                    <Grid item xs={12} sm={12} mt={5}>
                      <hr />
                      <Typography>
                        Bank Details of {selectedBank?.bankAccountHolderName}
                      </Typography>
                      <Grid
                        container
                        mt={3}
                        sx={{ width: "100%" }}
                        rowSpacing={3}
                      >
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Name{" "}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.bankAccountHolderName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Bank Name{" "}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.bankName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Account Number{" "}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.accountNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Bank Branch{" "}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.bankBranch}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            GST Number{" "}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.gstNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Pan Number{" "}
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.panNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Ifsc Code
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.ifscCode}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Micr Number
                          </Typography>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-size": "13px",
                            }}
                          >
                            {selectedBank?.micrNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            PAN Card{" "}
                          </Typography>
                          <Tooltip title="Download PAN card">
                            <DownloadIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                downloadFile(selectedBank?.PANCardProof?.key)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="View PAN card">
                            <RemoveRedEyeIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                viewFile(selectedBank?.PANCardProof?.key)
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Passbook{" "}
                          </Typography>
                          <Tooltip title="Download Passbook">
                            <DownloadIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                downloadFile(selectedBank?.PassBookProof?.key)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="View Passbook">
                            <RemoveRedEyeIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                viewFile(selectedBank?.PassBookProof?.key)
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            Aadhar Proof{" "}
                          </Typography>
                          <Tooltip title="Download Aadhar">
                            <DownloadIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                downloadFile(selectedBank?.AadharProof?.key)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="View Aadhar">
                            <RemoveRedEyeIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                viewFile(selectedBank?.AadharProof?.key)
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              width: "50%",
                              "font-weight": "600 !important",
                              "font-size": "13px",
                            }}
                          >
                            GST Proof{" "}
                          </Typography>
                          <Tooltip title="Download GST Copy">
                            <DownloadIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                downloadFile(selectedBank?.GSTProof?.key)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="View GST Copy">
                            <RemoveRedEyeIcon
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                viewFile(selectedBank?.GSTProof?.key)
                              }
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default PaidTransaction;
