import React, { useState, useRef, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FloatLabel from "../../../UiComponents/FloatLabel/FloatLabel";
import Typography from "@mui/material/Typography";
import {
  GetUser,
  GetCompany,
  GetPolicyList,
  VerifyPolicyNumber,
  LoginPortalData,
  CreatePolicyMappingService,
  retreiveDataFromPolicy,
} from "../../../Service/_index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { Formik, Form, Field } from "formik";
import Box from "@mui/material/Box";
import {
  PostCreatePolicy,
  AutoFillPolicyDetails,
} from "../../../Service/_index";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastError } from "../../../UiComponents/Toaster/Toast";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import swal from "sweetalert";
import {
  AWS_DIRECTORY_NAME,
  checkUserType,
} from "../../../Shared/CommonConstant";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import AddCompany from "../../Master/CreateCompany/AddCompany";
import Loader from "../../../UiComponents/Loader/Loader";
import Dialog from "@mui/material/Dialog";
import { Transition } from "../../../UiComponents/Transition/Transition";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DatePicker, Space } from "antd";
const PaymentDetails = [
  {
    label: "Cash",
    value: 1,
  },
  {
    label: "Online",
    value: 2,
  },
  {
    label: "Cheque",
    value: 3,
  },
  {
    label: "DD",
    value: 4,
  },
];

const initialValues = {
  userId: "",
  issueDate: "",
  companyId: "",
  loginId: {
    //added by gokul
    companyId: "",
    userId: "",
  },
  loginIdFull: "", //added by gokul
  bookingCode: "", //Added by Arun
  subBookingCode: "", //Added by Arun
  bookingCodeId: "", //Added by Arun
  subBookingCodeId: "", //Added by Arun
  policyNumber: "",
  totalPremium: "",
  paymentMode: "",
  chequeNumber: "",
  chequeDate: "",
  bankName: "",
  PolicyFile: "",
  OtherFile: "",
  policyPortal: "",
};

const PolicyPortal = [
  {
    label: "Company Portal",
    value: 1,
  },
  {
    label: "Broker Portal",
    value: 2,
  },
];

const Policycreate = () => {
  let formRef = useRef();
  const UserType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [showPaymentMethod, setShowPaymentMethod] = useState("");
  const [policyNumbers, setPolicyNumbers] = useState([]);
  const [openCompanyDrawer, setOpenCompanyDrawer] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectPortal, setSelectedPortal] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [refreshCount, setRefreshCount] = useState(0);
  const [refreshPolicyNumber, setRefreshPolicyNumber] = useState(0);
  const [openLoader, setOpenLoader] = useState(false);
  const [showPolicyErrMsg, setShowPolicyErrMsg] = useState("");
  const [isAllow, setIsAllow] = useState({});
  const [resetFileKey, setResetFileKey] = useState(1);
  const [fileTitle, setFileTitle] = useState({});
  const [disable, setDisable] = useState(true);
  const [displayMappingBtn, setDisplayMappingBtn] = useState(false); //Changes by Arun
  const [pdfData, setPdfData] = useState({});

  const CreatePolicySchema = Yup.object().shape({
    policyNumber: Yup.string().required("Policy Number Required"),
    userId: Yup.string().required("User Name Required"),
    companyId: Yup.string().required("Company Name Required"),
    issueDate: Yup.string().required("Issue Date Required"),
    totalPremium: Yup.string().required("Total Premium Required"),
    policyPortal: Yup.string().required("Policy Portal Required"),
    paymentMode: Yup.string().required("Payment Mode Required"),
    PolicyFile: Yup.string().required("Policy File Required"),
    chequeNumber: Yup.string().required("Cheque Number Required"),
    chequeDate: Yup.string().required("Cheque Date Required"),
    bankName: Yup.string().required("Bank Name Required"),
    // loginIdFull: Yup.string().required("LoginId is required"),
  });

  const GetCompanyDetails = () => {
    GetCompany({ isAscending: true }).then((res) => {
      const modifiedCompanyDetails = res?.data
        .filter((e) => e.isEnabled === true)
        .map((e) => {
          return {
            ...e,
            label: e.shortName,
            value: e._id,
          };
        })
        .filter(Boolean);

      setCompanyDetails(modifiedCompanyDetails);
    });
  };
  const GetUserDetails = () => {
    const bodyData = {
      requesttype: "TABLE",
      isAscending: true,
    };
    GetUser(bodyData).then((res) => {
      const modifiedUserDetails = res?.data
        ?.map((e) => {
          if (e.userType.includes("user") && e.isEnabled === true) {
            return {
              ...e,
              label: e.name + " - " + e.mobileNumber + " - " + e.email,
              value: e._id,
            };
          }
        })
        .filter(Boolean);
      setUserDetails(modifiedUserDetails);
    });
  };

  // Added by Arun
  const AutoFillFunction = (data) => {
    if (!data.PolicyFile) {
      ToastError("Please, Upload policy file");
      return true;
    }
    setOpenLoader(true);
    let formData = new FormData();
    formData.append(
      AWS_DIRECTORY_NAME.AWS_POLICY_FILE_DIRECTORY_NAME,
      data?.PolicyFile
    );

    retreiveDataFromPolicy(formData).then((res) => {
      setOpenLoader(false);
      // formRef.setFieldValue("cc", res?.data?.cc);
      // formRef.setFieldValue("gvw", res?.data?.gvw);
      setPdfData(res?.data);
      formRef.setFieldValue("policyNumber", res?.data?.policyNumber);
      // formRef.setFieldValue(
      //   "registrationNumber",
      //   res?.data?.registrationNumber
      // );
      // formRef.setFieldValue("registrationYear", res?.data?.registrationYear);
      // formRef.setFieldValue("seatingCapacity", res?.data?.seatingCapacity);
      formRef.setFieldValue(
        "totalPremium",
        String(res?.data?.totalPremium)?.replace(/,/g, "")
      );
      const ResCompany = res.data.companyName ? res.data.companyName : "";
      const foundCompany = companyDetails.find(
        (company) => company.companyName === ResCompany.toUpperCase()
      );
      if (res?.data?.issueDate) {
        const dateStr = res.data.issueDate;
        const daysjsDate = dayjs(dateStr, "DD/MM/YYYY");
        console.log("daysjsDate -----", daysjsDate);
        formRef.setFieldValue("issueDate", daysjsDate.$d);
      }
      // if (foundCompany) {
      //   formRef.setFieldValue("companyId", foundCompany?.value);
      //   setSelectedCompany(foundCompany?.companyName);
      // } else {
      //   formRef.setFieldValue("companyId", null);
      //   setSelectedCompany("");
      //   ToastError(
      //     `" ${ResCompany.toUpperCase()} "  Not added yet, Please add that Company !`
      //   );
      // }
    });
  };

  const GetPolicyDetails = () => {
    GetPolicyList().then((res) => {
      const policyNumbers = res?.data?.map((e) => e.policyNumber);
      setPolicyNumbers(policyNumbers);
    });
  };

  const BackToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  React.useEffect(() => {
    GetUserDetails();
    GetCompanyDetails();
    GetPolicyDetails();
  }, []);

  const PaymentFunction = (e) => {
    setShowPaymentMethod(e?.value);
    formRef.setFieldValue("paymentMode", e?.label);
  };

  const LoginUserId = sessionStorage.getItem("UserId");

  const SaveAndExit = (data, keys) => {
    const UserName = sessionStorage.getItem("UserId");
    data.userId = isAllow.isUser ? LoginUserId : data?.userId;
    data.policyNumber = data.policyNumber?.replace(/[^a-zA-Z0-9/-]/g, "");
    data.totalPremium = String(data?.totalPremium)?.replace(/,/g, "");
    data.createdBy = UserName;
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    const loginId = data?.loginId;
    formData.set("loginId", JSON.stringify(loginId));
    formData.append(
      AWS_DIRECTORY_NAME.AWS_POLICY_FILE_DIRECTORY_NAME,
      data?.PolicyFile
    );
    formData.append(
      AWS_DIRECTORY_NAME.AWS_OTHER_FILE_DIRECTORY_NAME,
      data.OtherFile
    );

    console.log("Form:", formData);

    // const validateUser = data?.loginIdFull && UserType==="CLIENT"
    // const validatePolicyPortal = data?.policyPortal && UserType === "CLIENT";
    // console.log(data?.PolicyFile)
    if (
      data?.userId &&
      data?.PolicyFile &&
      data?.issueDate &&
      data?.companyId &&
      data?.policyNumber &&
      data?.totalPremium &&
      data?.paymentMode
      // (UserType === "CLIENT" ? (data?.loginIdFull ? true : false) : true) &&
      // (UserType === "CLIENT" ? (data?.policyPortal ? true : false) : true)
    ) {
      setOpenLoader(true);
      // Changes by Arun
      if (displayMappingBtn) {
        CreatePolicyMappingService(formData)
          .then((res) => {
            setOpenLoader(false);
            navigate("/policyList");
            swal({
              title: "Policy Mapping Created Successfully!",
              icon: "success",
              timer: 4000,
              buttons: true,
            });
          })
          .catch((err) => ToastError(err));
      }
      // End of changes
      else {
        if (UserType == "user") {
          formData.delete("bookingCodeId");
          formData.delete("subBookingCodeId");
        }
        PostCreatePolicy(formData)
          .then((res) => {
            if (policyNumbers.includes(data?.policyNumber)) {
              ToastError("Policy Number Already Exists");
              setSelectedCompany("");
              setSelectedPortal("");
              setSelectedUser("");
              setSelectedPaymentMode("");
              BackToTop();
              setRefreshCount(refreshCount + 1);
              formRef.setFieldValue("paymentMode", null);
            } else if (keys === "SaveAndExit") {
              navigate("/policyList");
              swal({
                title: "Policy Created Successfully!",
                icon: "success",
                timer: 4000,
                buttons: true,
              });

              setDisable(false);
            } else if (keys === "CreateSameUser") {
              setResetFileKey(resetFileKey + 1);
              formRef.setFieldValue("PolicyFile", null);
              formRef.setFieldValue("OtherFile", null);
              setFileTitle(null);
              formRef.setFieldValue("policyNumber", "");
              setRefreshPolicyNumber(refreshPolicyNumber + 1);
              formRef.setFieldValue("totalPremium", "");
              BackToTop();
              swal({
                title: "Policy Created Successfully!",
                icon: "success",
                timer: 4000,
                buttons: true,
              });
              setDisable(false);
            } else {
              setResetFileKey(resetFileKey + 1);
              formRef.setFieldValue("PolicyFile", null);
              formRef.setFieldValue("OtherFile", null);
              setFileTitle("");
              setSelectedCompany("");
              setSelectedPortal("");
              setSelectedUser("");
              setSelectedPaymentMode("");
              setSelectedLoginPortal("");
              BackToTop();
              formRef.resetForm();
              setRefreshCount(refreshCount + 1);
              setShowPaymentMethod("");
              formRef.setFieldValue("paymentMode", null);
              swal({
                title: "Policy Created Successfully!",
                icon: "success",
                timer: 4000,
                buttons: true,
              });
              setDisable(false);
            }
          })
          .catch((err) => {
            ToastError(err?.message);
          })
          .finally(() => {
            setOpenLoader(false);
          });
      }
    } else {
      ToastError("Enter all fields");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const VerifyPolicyNumberFunction = (e) => {
    VerifyPolicyNumber(e)
      .then((res) => {
        // console.log("re", res);
        setShowPolicyErrMsg("");
        setDisplayMappingBtn(false); // Changes by Arun
      })
      .catch((err) => {
        console.log("rsse", err);
        const userName = sessionStorage.getItem("name");
        // Changes by Arun
        if (isAllow.isUser && !err.response.data.message.includes(userName)) {
          setShowPolicyErrMsg("");
          setDisplayMappingBtn(true);
        } else {
          setDisplayMappingBtn(false);
          setShowPolicyErrMsg(err.response.data.message);
          formRef.setFieldValue("policyNumber", null);
          setRefreshPolicyNumber(refreshPolicyNumber + 1);
        }
      });
  };

  // loginPortal added by gokul...
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedLoginPortal, setSelectedLoginPortal] = useState(null);
  const [loginPortalDetails, setLoginPortalDetails] = useState([]);

  const GetLoginPortalData = () => {
    LoginPortalData({
      userId: selectedUserId,
      companyId: selectedCompanyId,
      isActive: true,
    }).then((res) => {
      const filterData1 = res?.data.filter((item) => {
        if (item?.userId === selectedUserId) {
          if (item?.companyId === selectedCompanyId) {
            return item;
          }
        }
      });
      const UserAllData = res?.data.filter((item) => {
        if (item?.userId === "All") {
          if (item?.companyId === selectedCompanyId) {
            return item;
          }
        }
      });

      const UserAdminData = res?.data.filter((item) => {
        if (item?.userId === "Admin") {
          if (item?.companyId === selectedCompanyId) {
            return item;
          }
        }
      });

      setLoginPortalDetails([...filterData1, ...UserAllData, ...UserAdminData]);
    });
  };

  useEffect(() => {
    if (selectedUserId) GetLoginPortalData();
    else setLoginPortalDetails([]);
  }, [selectedUserId, selectedCompanyId]);

  React.useEffect(() => {
    const isallowObj = checkUserType(UserType);
    setIsAllow(isallowObj);
  }, [UserType]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className="Master_Header_Container">
          <Grid item xs={12} sm={3}>
            <Typography className="Master_Header_Heading">
              Create Policy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} />
        </Grid>
      </Box>
      <Paper sx={{ padding: "10px 0 0 0", margin: "0 4px 20px 12px" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={CreatePolicySchema}
          innerRef={(ref) => {
            if (ref) {
              formRef = ref;
            }
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Typography
                sx={{ display: isAllow.isUser ? "none" : "block", ml: 2 }}
              >
                User
              </Typography>
              <Grid
                container
                sx={{ p: 2, display: isAllow.isUser ? "none" : "block" }}
                columnSpacing={2}
              >
                <Grid item xs={12} sm={4}>
                  <FloatLabel
                    label="Select User"
                    value={values?.userId}
                    important="*"
                  >
                    <Autocomplete
                      name="userId"
                      disablePortal
                      className="AutoComplete_InputBox"
                      id="combo-box-demo"
                      value={selectedUser}
                      options={userDetails}
                      onChange={(e, v) => {
                        // console.log(v)
                        if (!v) {
                          setSelectedUser("");
                        } else {
                          setSelectedUser(
                            v?.name + " - " + v?.mobileNumber + " - " + v?.email
                          );
                        }
                        setSelectedUserId(v?._id);
                        setFieldValue("userId", v?._id || "");
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option?.name +
                            " - " +
                            option?.mobileNumber +
                            " - " +
                            option?.email}
                        </li>
                      )}
                      isOptionEqualToValue={(e, v) => e.name === v}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FloatLabel>
                  <div className="errorMessage ">
                    {errors.userId && touched.userId ? (
                      <div>{errors.userId}</div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grid>
              </Grid>
              <hr style={{ display: isAllow.isUser ? "none" : "block" }} />
              <Typography sx={{ ml: 2 }}>Policy Details</Typography>
              <Grid container sx={{ p: 2 }} columnSpacing={2}>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="issueDate"
                      className="Date_Picker w-100"
                      onChange={(e) => setFieldValue("issueDate", e?.$d)}
                      key={refreshCount}
                      format={"DD/MM/YYYY"}
                    />
                  </LocalizationProvider>
                  <div className="errorMessage mb-2 mt-2">
                    {errors.issueDate && touched.issueDate ? (
                      <div>{errors.issueDate}</div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FloatLabel
                    label="Select Company"
                    value={values?.companyId}
                    important="*"
                  >
                    <Autocomplete
                      name="companyId"
                      className="AutoComplete_InputBox"
                      disablePortal
                      id="combo-box-demo"
                      value={selectedCompany}
                      options={companyDetails}
                      onChange={(e, v) => {
                        setSelectedCompany(v?.shortName);
                        setSelectedCompanyId(v?._id);
                        setFieldValue("companyId", v?._id);
                      }}
                      clearIcon={false}
                      renderInput={(params) => <TextField {...params} />}
                      renderOption={(props, option, state) => {
                        const isFirstOption = state.index === 0;
                        return (
                          <>
                            {isFirstOption && (
                              <li
                                style={{
                                  padding: "10px 0 4px 16px",
                                  backgroundColor: "gray",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                key="add-user"
                                onClick={() => setOpenCompanyDrawer(true)}
                              >
                                Add Company +
                              </li>
                            )}
                            <li {...props} key={option._id}>
                              {option.label}
                            </li>
                          </>
                        );
                      }}
                    />
                  </FloatLabel>
                  <div className="errorMessage mb-2">
                    {errors.companyId && touched.companyId ? (
                      <div>{errors.companyId}</div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: isAllow.isUser ? "none" : "block" }}
                >
                  <FloatLabel
                    label="Select Policy Portal"
                    value={values?.policyPortal}
                    important="*"
                  >
                    <Autocomplete
                      className="AutoComplete_InputBox"
                      name="policyPortal"
                      disablePortal
                      value={selectPortal}
                      options={PolicyPortal}
                      onChange={(e, v) => {
                        setSelectedPortal(v?.label);
                        setFieldValue("policyPortal", v?.label);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      isOptionEqualToValue={(e, v) => e?.value === v?.value}
                    />
                    <div className="errorMessage mb-2 mt-2">
                      {errors.policyPortal && touched.policyPortal ? (
                        <div>{errors.policyPortal}</div>
                      ) : (
                        " "
                      )}
                    </div>
                  </FloatLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: isAllow.isUser ? "none" : "block" }}
                >
                  <FloatLabel
                    label="Select LoginId"
                    value={values?.loginIdFull}
                    important="*"
                  >
                    <Autocomplete
                      name="loginIdFull"
                      disablePortal
                      className="AutoComplete_InputBox"
                      id="combo-box-demo"
                      value={selectedLoginPortal}
                      options={loginPortalDetails}
                      key={refreshCount}
                      onChange={(e, v) => {
                        setSelectedLoginPortal(v?.loginPortal);
                        setFieldValue("loginId.companyId", v?.companyId);
                        setFieldValue("loginId.userId", v?.userId);
                        setFieldValue("loginIdFull", v?.loginPortal);
                        setFieldValue("bookingCode", v?.bookingCode);
                        setFieldValue("subBookingCode", v?.subBookingCode);
                        setFieldValue("bookingCodeId", v?.bookingCodeId);
                        setFieldValue("subBookingCodeId", v?.subBookingCodeId);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.loginPortal}
                        </li>
                      )}
                      isOptionEqualToValue={(e, v) => e.loginPortal === v}
                      getOptionLabel={(option) =>
                        typeof option === "object" && option !== null
                          ? option.loginPortal || "Unknown"
                          : option
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <div className="errorMessage mb-2 mt-2">
                      {errors.loginIdFull && touched.loginIdFull ? (
                        <div>{errors.loginIdFull}</div>
                      ) : (
                        " "
                      )}
                    </div>
                  </FloatLabel>
                </Grid>
                {/* Added by Arun */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: isAllow.isUser ? "none" : "block" }}
                >
                  <FloatLabel
                    label="Booking code"
                    value={values.bookingCode}
                    readOnly={true}
                  >
                    <Field
                      key={refreshPolicyNumber}
                      name="bookingCode"
                      type="string"
                      className="textField w-100"
                    />
                  </FloatLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: isAllow.isUser ? "none" : "block" }}
                >
                  <FloatLabel
                    label="Sub Bookingcode"
                    value={values.subBookingCode}
                    readOnly={true}
                  >
                    <Field
                      key={refreshPolicyNumber}
                      name="subBookingCode"
                      type="string"
                      className="textField w-100"
                    />
                  </FloatLabel>
                </Grid>
              </Grid>
              <hr />
              <Typography sx={{ ml: 2 }}>Premium Details</Typography>
              <Grid container sx={{ p: 2 }} columnSpacing={2}>
                <Grid item xs={12} sm={4}>
                  <FloatLabel
                    label="Policy Number"
                    value={values.policyNumber}
                    important="*"
                  >
                    <Field
                      className="textField w-100"
                      name="policyNumber"
                      id="policyNumber"
                      onKeyDown={handleKeyDown}
                      key={refreshPolicyNumber}
                      onBlur={(e) => VerifyPolicyNumberFunction(e.target.value)}
                    />
                  </FloatLabel>
                  <div className="errorMessage mb-2">{showPolicyErrMsg}</div>
                  <div className="errorMessage mb-2 mt-2">
                    {errors.policyNumber && touched.policyNumber ? (
                      <div>{errors.policyNumber}</div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FloatLabel
                    label="Total Premium"
                    value={values.totalPremium}
                    important="*"
                  >
                    <Field
                      key={refreshPolicyNumber}
                      name="totalPremium"
                      type="number"
                      className="textField w-100"
                    />
                    <div className="errorMessage mb-2 mt-2">
                      {errors.totalPremium && touched.totalPremium ? (
                        <div>{errors.totalPremium}</div>
                      ) : (
                        " "
                      )}
                    </div>
                  </FloatLabel>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FloatLabel
                    label="Payment Mode"
                    value={showPaymentMethod}
                    important="*"
                  >
                    <Autocomplete
                      className="AutoComplete_InputBox"
                      options={PaymentDetails}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      clearIcon={false}
                      value={selectedPaymentMode}
                      name="paymentMode"
                      onChange={(option, value) => {
                        setSelectedPaymentMode(value?.label);
                        PaymentFunction(value);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <div className="errorMessage mb-2 mt-2">
                      {errors.paymentMode && touched.paymentMode ? (
                        <div>{errors.paymentMode}</div>
                      ) : (
                        " "
                      )}
                    </div>
                  </FloatLabel>
                </Grid>
                {showPaymentMethod === 3 ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <FloatLabel
                        label="Cheque Number"
                        value={values.chequeNumber}
                        important="*"
                      >
                        <Field
                          name="chequeNumber"
                          className="textField w-100"
                        />
                        <div className="errorMessage mb-2 mt-2">
                          {errors.chequeNumber && touched.chequeNumber ? (
                            <div>{errors.chequeNumber}</div>
                          ) : (
                            " "
                          )}
                        </div>
                      </FloatLabel>
                    </Grid>
                    <Grid item xs={12} sm={4} className="datePicker">
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                      <DatePicker
                        name="chequeDate"
                        className="Date_Picker w-100"
                        onChange={(e) =>
                          setFieldValue("chequeDate", e?.$d ? e?.$d : null)
                        }
                      />
                      {/* </LocalizationProvider> */}
                      <div className="errorMessage mb-2 mt-2">
                        {errors.chequeDate && touched.chequeDate ? (
                          <div>{errors.chequeDate}</div>
                        ) : (
                          " "
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FloatLabel
                        label="Bank Name"
                        value={values.bankName}
                        important="*"
                      >
                        <Field className="textField w-100" name="bankName" />
                        <div className="errorMessage mb-2 mt-2">
                          {errors.bankName && touched.bankName ? (
                            <div>{errors.bankName}</div>
                          ) : (
                            " "
                          )}
                        </div>
                      </FloatLabel>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <hr />
              <Typography sx={{ ml: 2 }}>Files</Typography>
              <Grid container sx={{ p: 2 }} columnSpacing={2}>
                <Grid item xs={12} sm={4} className="File-Upload">
                  <FileUploader
                    handleChange={(e) => {
                      console.log("e", e);
                      setFieldValue("PolicyFile", e);
                      setFileTitle({ ...fileTitle, policyFile: e.name });
                    }}
                    name="PolicyFile"
                    label="Upload Your Policy File"
                    dropMessageStyle={{
                      color: "red",
                      border: "none",
                      borderRadius: "0px",
                    }}
                    types={["PDF"]}
                    key={resetFileKey}
                  />
                  <div className="fileTitle">
                    {fileTitle?.policyFile ? fileTitle?.policyFile : null}
                  </div>
                  <div className="errorMessage">
                    {errors.PolicyFile && touched.PolicyFile ? (
                      <div>{errors.PolicyFile}</div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} className="File-Upload">
                  <FileUploader
                    handleChange={(e) => {
                      setFieldValue("OtherFile", e);
                      setFileTitle({ ...fileTitle, otherFile: e.name });
                    }}
                    name="OtherFile"
                    label="Upload Your Other documents"
                    dropMessageStyle={{
                      color: "red",
                      border: "none",
                      borderRadius: "0px",
                    }}
                    multiple={false}
                    key={resetFileKey}
                  />
                  <div className="fileTitle">
                    {fileTitle?.otherFile ? fileTitle?.otherFile : null}
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                  <button
                    className="w-100 Common_Button"
                    onClick={(e) => AutoFillFunction(values)}
                  >
                    Auto Fill
                  </button>
                </Grid> */}
                {/* <Grid item xs={12} sm={12}>
                  {pdfData ? (
                    <div style={{ color: "BLUE" }}>
                      {" "}
                      {JSON.stringify(pdfData, null, 2)}
                    </div>
                  ) : (
                    ""
                  )}
                </Grid> */}
              </Grid>
              <hr />
              <Grid container columnSpacing={2} sx={{ p: 2 }}>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  sx={{ display: displayMappingBtn ? "none" : "block" }}
                >
                  <button
                    className="w-100 Common_Button"
                    onClick={(e) => SaveAndExit(values, "SaveAndExit")}
                  >
                    Save & Exit
                  </button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1.5}
                  sx={{ display: displayMappingBtn ? "none" : "block" }}
                >
                  <button
                    className="w-100 Common_Button"
                    onClick={(e) => SaveAndExit(values, "CreateSameUser")}
                  >
                    {UserType == "user" ? "Create New" : "Create Same User"}
                  </button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1.5}
                  sx={{ display: isAllow.isUser ? "none" : "block" }}
                >
                  <button
                    className="w-100 Common_Button"
                    onClick={(e) => SaveAndExit(values, "CreateOtherUser")}
                  >
                    Create Other User
                  </button>
                </Grid>
                {/* Changes by Arun */}
                <Grid
                  item
                  xs={12}
                  sm={2}
                  sx={{ display: !displayMappingBtn ? "none" : "block" }}
                >
                  <button
                    className="w-100 TabelButton"
                    type="submit"
                    onClick={(e) => SaveAndExit(values, "MappingRequired")}
                  >
                    Mapping Required
                  </button>
                </Grid>
                {/* End of changes */}
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      <Loader open={openLoader} />
      <Dialog
        open={openCompanyDrawer}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            maxWidth: "50%",
          },
        }}
      >
        {openCompanyDrawer ? (
          <AddCompany
            title="Create"
            formType="add"
            setOpenCompanyDrawer={setOpenCompanyDrawer}
            GetActiveData={GetCompanyDetails}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default Policycreate;
