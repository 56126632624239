import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { visuallyHidden } from "@mui/utils";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import { ToastError, ToastSuccess } from "../../../UiComponents/Toaster/Toast";
import FloatLabel from "../../../UiComponents/FloatLabel/FloatLabel";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import TextField from "@mui/material/TextField";
import ViewCCentry from "./ViewCCentry";
import {
  CloudOffIcon,
  SearchIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  ReportProblemIcon,
} from "../../../Resources/Icons/icons";
import {
  GetCompany,
  GetPolicyFileById,
  GetSubBookingCode,
  GetSubProduct,
} from "../../../Service/_index";
import {
  GetFilterCCEntry,
  GetFilterCCEntryAll,
  unlinkTicketNumber,
  ReframeEndDateTimeFormat,
  uploadCCEntryData,
} from "../../../Service/SearchPolicy";
import { CSVLink } from "react-csv";
import { Dialog } from "@mui/material";
import { DatePicker } from "antd";
import ConfirmBox from "../../../UiComponents/ConfirmBox/ConfirmBox";
import { checkUserType } from "../../../Shared/CommonConstant";
import Loader from "../../../UiComponents/Loader/Loader";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { FileUpload, UploadFile } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";

const { RangePicker } = DatePicker;

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const FilterCCOption = [
  {
    label: "Pending Policy",
    value: "approvePending",
  },
  {
    label: "Entry Done",
    value: "entryDone",
  },
  {
    label: "Already Exist",
    value: "alreadyExist",
  },
  {
    label: "Entry Date",
    value: "entryDate",
  },
  {
    label: "Approve Date",
    value: "approveDate",
  },
];
const tempHeadCells = [
  {
    id: "issueDate",
    placeMent: true,
    disablePadding: false,
    label: "Policy Date",
  },
  {
    id: "companyName",
    placeMent: true,
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "policyNumber",
    placeMent: true,
    disablePadding: false,
    label: "Policy Number",
  },

  {
    id: "customerName",
    placeMent: true,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "registrationNumber",
    placeMent: true,
    disablePadding: false,
    label: "Reg Number",
  },
  {
    id: "Product",
    placeMent: true,
    disablePadding: false,
    label: "Product",
  },
  {
    id: "SubProduct",
    placeMent: true,
    disablePadding: false,
    label: "SubProduct",
  },
  {
    id: "totalPremium",
    placeMent: true,
    disablePadding: false,
    label: "Total Premium",
  },
  {
    id: "email",
    placeMent: true,
    disablePadding: false,
    label: "User Email",
  },
  {
    id: "ticketNumber",
    placeMent: true,
    disablePadding: false,
    label: "Ticket Number",
  },
  {
    id: "ticketStatus",
    placeMent: true,
    disablePadding: false,
    label: "Ticket Status",
  },
  {
    id: "action",
    placeMent: false,
    disablePadding: false,
    label: "Action",
  },
];

// Added by Arun
const headCells =
  sessionStorage.getItem("userType") == "SUBCODE"
    ? tempHeadCells.filter((e) => e.label !== "User Email")
    : tempHeadCells;

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            className="TableHeader"
            key={headCell.id}
            align={headCell.placeMent ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontSize: "16px", fontWeight: 600 }}
            style={{ minWidth: headCells.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const CCEntry = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [policyList, setPolicyList] = React.useState([]);
  const [excelList, setExcelList] = React.useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [subBookingCodeDetails, setSubBookingCodeDetails] = useState([]);
  const [openViewCC, setOpenViewCC] = useState(false);
  const [resetValue, setResetValue] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [edit, setEdit] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndtDate, setSelectedEndDate] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [filterBy, setFilterBy] = useState({});
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [filter, setFilter] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [ccEntryFile, setCCEntryFile] = useState(null);
  const [resetFileKey, setResetFileKey] = useState(1);
  const csvLinkRef = React.useRef(null);

  const uploadExcel = async () => {
    console.log("csvLinkRef.current.link : ", ccEntryFile);
    if (!ccEntryFile) {
      ToastError("Please upload cc entry excel data");
      return true;
    }
    let formData = new FormData();
    formData.append(ccEntryFile, ccEntryFile);
    setOpenLoader(true);
    await uploadCCEntryData(formData)
      .then((res) => {
        if (res?.message) {
          ToastSuccess(res.message);
          setCCEntryFile(null);
        }
        // UniversalSearch(true);
      })
      .finally(() => {
        setOpenLoader(false);
      });
  };
  // For pagination
  const [count, setCount] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const defaultQueryParams = {
    page: 0,
    limit: 5,
    search: "",
    skip: 0,
    sort: "",
    isDownloadExcel: false,
  };
  const [queryParam, setQueryParam] = useState(defaultQueryParams);
  const [isInitialized, setIsInitialized] = useState(false);

  const visibleRows = React.useMemo(
    () =>
      stableSort(filter.fn(policyList), getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filter, policyList]
  );

  // Added by Arun
  const resetQueryParams = () => {
    setQueryParam(defaultQueryParams);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    console.log("newPage ", newPage);
    setQueryParam({
      ...queryParam,
      page: newPage,
      skip: newPage * rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setQueryParam({ ...queryParam, limit: event.target.value, page: 0 });
    setPage(0);
  };

  const onSearch = (e) => {
    let target = e.target.value;
    setQueryParam({
      ...queryParam,
      search: target,
    });
    // setFilter({
    //   fn: (items) => {
    //     if (target.value == "") return items;
    //     else
    //       return items.filter((el) =>
    //         `${el?.companyId?.companyName},${el.policyNumber},${
    //           el.customerName
    //         },${el.registrationNumber},${el.odPremium},${el.netPremium},${
    //           el.totalPremium
    //         },${el?.userId?.email},${el.ticketNumber},${moment(
    //           el.issueDate
    //         ).format("D-M-Y")}`
    //           .toLowerCase()
    //           .includes(target.value.toLowerCase())
    //       );
    //   },
    // });
    // setPage(0);
  };

  const GetCompanyDetails = () => {
    GetCompany({ isAscending: true }).then((res) => {
      const modifiedCompany = res.data
        .map((e) => {
          if (e.isEnabled) {
            return {
              ...e,
              label: e.shortName,
              value: e._id,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);
      setCompanyDetails(modifiedCompany);
    });
  };

  const GetSubBookingCodeDetails = () => {
    GetSubBookingCode({ isAscending: "combineData" }).then((res) => {
      const SubBookingCodeDetails = res.data
        .map((e) => {
          if (e.isEnabled && e.cc === "Yes") {
            return {
              ...e,
              label: e.bookingCode + " - " + e.subBookingCode,
              value: e._id,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);
      setSubBookingCodeDetails(SubBookingCodeDetails);
    });
  };
  const [productData, setProductData] = useState([]);
  const GetProductDetails = () => {
    GetSubProduct({ isAscending: "combineData" }).then((res) => {
      const subProductDetails = res.data
        .map((e) => {
          if (e.isEnabled) {
            return {
              ...e,
              label: `${e?.product} - ${e?.subProduct}`,
              value: e?._id,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);

      setProductData(subProductDetails);
    });
  };

  const UniversalSearch = async (isFirstPage) => {
    console.log("UniversalSearch : ", filterBy);
    if (filterBy?.bookingCodeId && filterBy?.subBookingCodeId) {
      setOpenLoader(true);
      // Changes by Arun
      let endDate = null;
      if (selectedEndtDate) {
        endDate = await ReframeEndDateTimeFormat(selectedEndtDate);
      }
      // For pagination
      // if (isFirstPage) {
      //   queryParam["page"] = 1;
      // }

      await GetFilterCCEntry({
        ...filterBy,
        startDate: selectedStartDate,
        endDate: endDate,
        // For pagination
        page: queryParam.page,
        limit: queryParam.limit,
        search: queryParam.search,
        sort: queryParam.sort,
        isDownloadExcel: queryParam.isDownloadExcel,
      }).then((res) => {
        setOpenLoader(false);
        const FilteredData = res?.data?.tableData.map((e) => {
          return {
            ...e,
            InformatDate: moment(e.issueDate).format("DD/MM/YYYY"),
          };
        });
        if (!queryParam.isDownloadExcel) {
          setPolicyList(FilteredData);
        }
        setExcelList(FilteredData);
        setCount(res?.data?.totalRecords);
        setTotalPremium(res?.data?.totalPremium);
        // For pagination
        if (isFirstPage) {
          setPage(0);
        }
      });
    } else {
      setPolicyList([]);
      setExcelList([]);
      ToastError("Booking code and SubBooking code required!!");
    }
  };

  const apiCallForDownloadExcel = async () => {
    console.log("apiCallForDownloadExcel : ", filterBy);
    if (filterBy?.bookingCodeId && filterBy?.subBookingCodeId) {
      setOpenLoader(true);
      // Changes by Arun
      let endDate = null;
      if (selectedEndtDate) {
        endDate = await ReframeEndDateTimeFormat(selectedEndtDate);
      }
      // For pagination
      // if (isFirstPage) {
      //   queryParam["page"] = 1;
      // }

      await GetFilterCCEntry({
        ...filterBy,
        startDate: selectedStartDate,
        endDate: endDate,
        // For pagination
        isDownloadExcel: true,
      }).then((res) => {
        setOpenLoader(false);
        const FilteredData = res?.data?.tableData.map((e) => {
          return {
            ...e,
            InformatDate: moment(e.issueDate).format("DD/MM/YYYY"),
          };
        });
        let wb = new ExcelJS.Workbook();
        let workbookName = `CC Entry Data ${new Date()}.xlsx`;
        let worksheetName = "CC Entry";
        let ws = wb.addWorksheet(worksheetName, {
          properties: {
            tabColor: { argb: "FFFF0000" },
          },
        });

        ws.columns = isAllow.isSubCode
          ? header.filter((element) => element.header != "User Email")
          : header;
        // console.log("FormatedData  ", FormatedData);
        ws.addRows(FilteredData);

        ws.eachRow((row, rowNumber) => {
          row.eachCell({ includeEmpty: true }, (cell) => {
            // Set default border style for all cells (including empty cells)
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        wb.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            workbookName
          );
        });
      });
    } else {
      setPolicyList([]);
      setExcelList([]);
      ToastError("Booking code and SubBooking code required!!");
    }
  };

  const CCEntryFunction = (row, data) => {
    setOpenLoader(true);
    setEdit(data);
    setOpenViewCC(true);
    setSelectedData(row);
    // setOpenLoader(false)
  };

  const DeleteCCdata = async (row) => {
    setOpenConfirmBox(true);
    setSelectedData(row);
  };

  const DeleteFunction = () => {
    unlinkTicketNumber(selectedData._id).then((res) => {
      UniversalSearch(true);
      setOpenConfirmBox(false);
    });
  };

  // let totalPremium = 0;
  // policyList?.map((row) => {
  //   const premiumWithoutCommas = row?.totalPremium.replace(/,/g, "");
  //   let totPremium = parseFloat(premiumWithoutCommas);
  //   totalPremium += totPremium;
  // });

  const header = [
    { header: "Policy Date", key: "InformatDate" },
    { header: "Company Name", key: "company" },
    { header: "Policy Number", key: "policyNumber" },
    { header: "Reg Number", key: "registrationNumber" },
    { header: "Product", key: "product" },
    { header: "SubProduct", key: "subProduct" },
    { header: "Total Premium", key: "totalPremium" },
    { header: "User Email", key: "email" },
    { header: "Ticket Number", key: "ticketNumber" },
  ];

  const csvFile = {
    filename: "CC Entry Data",
    headers: header,
    data: excelList,
  };

  React.useEffect(() => {
    GetCompanyDetails();
    GetSubBookingCodeDetails();
    GetProductDetails();
  }, []);

  const UserType = sessionStorage.getItem("userType");

  const [isAllow, setIsAllow] = useState({});
  React.useEffect(() => {
    const isallowObj = checkUserType(UserType);
    setIsAllow(isallowObj);
  }, []);

  React.useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      return;
    } else {
      UniversalSearch(false);
    }
  }, [queryParam.page, queryParam.limit, queryParam.search, queryParam.sort]);

  const OpenPolicyFile = (row) => {
    setOpenLoader(true);
    GetPolicyFileById(row?._id)
      .then((res) => {
        const pdfUrl = res?.data?.policyFile?.downloadURL;
        return pdfUrl;
      })
      .then((pdfUrl) => {
        setOpenLoader(false);
        // Changes by Arun
        const blobData = base64toBlob(pdfUrl, "application/pdf");
        const blobUrl = URL.createObjectURL(blobData);
        setOpenLoader(false);
        const pdfWindow = window.open("", "_blank");
        pdfWindow?.document?.write(
          // `<embed src="data:application/pdf;base64,${pdfUrl}" width="100%" height="100%" />`
          `<embed src="${blobUrl}" width="100%" height="100%" />`
        );
        // const pdfWindow = window.open("", "_blank");
        // pdfWindow?.document?.write(`<embed src="data:application/pdf;base64,${pdfUrl}" width="100%" height="100%" />`);
      });
  };

  // Changes by Arun
  const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const formatNumber = (num) => new Intl.NumberFormat("en-IN").format(num);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "10px 10px 0 10px",
        }}
        className="Master_Header_Container"
      >
        <Grid item sm={3} xs={12}>
          <FloatLabel
            label="Booking code & SubBooking code"
            value={filterBy?.subBookingCodeId}
          >
            <Autocomplete
              className="AutoComplete_InputBox"
              options={subBookingCodeDetails}
              onChange={(option, value) => {
                setFilterBy({
                  ...filterBy,
                  subBookingCodeId: value?._id,
                  bookingCodeId: value?.bookingCodeId,
                });
                resetQueryParams();
              }}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              key={resetValue}
            />
          </FloatLabel>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FloatLabel label="Select Company" value={filterBy?.companyId}>
            <Autocomplete
              disablePortal
              className="AutoComplete_InputBox"
              id="combo-box-demo"
              options={companyDetails}
              onChange={(option, value) => {
                setFilterBy({ ...filterBy, companyId: value?._id });
                resetQueryParams();
              }}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) =>
                option?._id === value._id
              }
            />
          </FloatLabel>
        </Grid>
        {/* <Grid item sm={2} xs={12}>
          <FloatLabel
            label="Product & SubProduct"
            value={filterBy?.subProductId}
          >
            <Autocomplete
              className="AutoComplete_InputBox"
              options={productData}
              onChange={(option, value) =>
                setFilterBy({
                  ...filterBy,
                  subProductId: value?._id,
                  productId: value?.productID,
                })
              }
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              key={resetValue}
            />
          </FloatLabel>
        </Grid> */}
        <Grid item sm={3} xs={12} className="datePicker">
          <FloatLabel label="Start date & End Date" value="react">
            <RangePicker
              placement="bottomLeft"
              className="textField w-100"
              style={{ borderRadius: "0px" }}
              onChange={(e) => {
                console.log("date e ", e);
                setSelectedStartDate(e ? e[0].$d : null);
                setSelectedEndDate(e ? e[1].$d : null);
                resetQueryParams();
              }}
              format="DD/MM/YYYY"
            />
          </FloatLabel>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FloatLabel label="Filter" value={filterBy?.status}>
            <Autocomplete
              className="AutoComplete_InputBox"
              options={FilterCCOption}
              onChange={(option, value) => {
                setFilterBy({ ...filterBy, status: value?.value });
                resetQueryParams();
              }}
              renderInput={(params) => <TextField {...params} />}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </FloatLabel>
        </Grid>
        {/* Changed by Arun */}
        <Grid item sm={3} xs={12}>
          <TextField
            className="textField w-100"
            placeholder="Policy Number"
            sx={{ color: "black", background: "white" }}
            onChange={(e) => {
              setFilterBy({ ...filterBy, policyNumber: e.target.value });
              resetQueryParams();
            }}
          />
        </Grid>
        <Grid item sm={1.3} xs={12}>
          <Button
            className="Master_Header_create_Button w-100"
            sx={{ width: { xs: "100%", sm: "30%" }, marginBottom: "8px" }}
            endIcon={<SearchIcon />}
            onClick={UniversalSearch.bind(null, true)}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className="Master_Header_Container" spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography className="Master_Header_Heading">CC Entry</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={1.5} /> */}

          <Grid
            item
            sm={10}
            xs={12}
            className="Count_Page"
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
            // mt={2}
            gap={1}
          >
            <Grid className="Count_1" sx={{ width: "24%" }}>
              <Typography className="Total">
                {count ? formatNumber(count) : 0}
              </Typography>
              <Typography className="Title">Policy Count </Typography>
            </Grid>
            <Grid className="Count_2" sx={{ width: "24%" }}>
              <Typography className="Total">
                {totalPremium ? formatNumber(totalPremium) : 0}
              </Typography>
              <Typography className="Title">Total Premium</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Input
              focused="false"
              className="w-100 Master_Header_Input"
              id="standard-adornment-password"
              onChange={onSearch}
              disableUnderline={true}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <IconButton aria-label="toggle password visibility">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Button
              className="Master_Header_create_Button w-100"
              onClick={() => {
                apiCallForDownloadExcel();
                // downloadExcel();
              }}
              endIcon={<DownloadIcon />}
            >
              Download Excel
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <Button
              className="Master_Header_create_Button w-100"
              onClick={() => {
                apiCallForDownloadExcel();
                // downloadExcel();
              }}
              endIcon={<FileUpload />}
            > */}
            <FileUploader
              handleChange={(e) => {
                console.log("e", e);
                setCCEntryFile(e);
              }}
              fileOrFiles={ccEntryFile}
              name="CCentryFile"
              label="Upload Excel"
              dropMessageStyle={{
                color: "red",
                border: "none",
                borderRadius: "0px",
              }}
              types={["XLSX", "XLS"]}
              key={resetFileKey}
            />
            {/* <CSVLink
                ref={csvLinkRef}
                // {...csvFile}
                data={excelList}
                headers={csvFile.headers}
                filename={csvFile.filename}
                style={{ display: "none" }}
              > */}
            {/* Upload Excel */}
            {/* </CSVLink> */}
            {/* </Button> */}
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Button
              className="Master_Header_create_Button w-100"
              onClick={() => {
                uploadExcel();
                // downloadExcel();
              }}
              endIcon={<FileUpload />}
            >
              Import Data
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div className="d-flex PageContainer">
        <Paper className={"container-fluid TableBox "}>
          <TableContainer className="TableContainer">
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {policyList?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row" padding="normal">
                        {moment(row.issueDate).format("D-MM-Y")}
                      </TableCell>
                      <TableCell align="left">
                        {row?.companyId?.shortName}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "blue" }}>
                        <label
                          onClick={() => OpenPolicyFile(row)}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {row?.policyNumber}
                        </label>
                      </TableCell>

                      <TableCell align="left">{row?.customerName}</TableCell>
                      <TableCell align="left">
                        {row?.registrationNumber}
                      </TableCell>
                      {/* <TableCell align="left">{row?.odPremium}</TableCell>
                      <TableCell align="left">{row?.netPremium}</TableCell> */}
                      <TableCell align="left">{row?.product}</TableCell>
                      <TableCell align="left">{row?.subProduct}</TableCell>
                      <TableCell align="left">{row?.totalPremium}</TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          display: isAllow?.isSubCode ? "none" : "table-cell",
                        }}
                      >
                        {row?.email}
                      </TableCell>
                      <TableCell align="left">{row?.ticketNumber}</TableCell>
                      <TableCell align="left">{row?.ticketStatus}</TableCell>
                      <TableCell align="center">
                        <Box className="ActionIcons">
                          {!row?.ticketNumber ? (
                            <Tooltip title="ccEntry">
                              <Button
                                className="Common_Button"
                                onClick={() => CCEntryFunction(row, "ccEntry")}
                                sx={{
                                  padding: "0px 40px",
                                  fontSize: "10px",
                                  display: isAllow?.isUser ? "none" : "block",
                                }}
                              >
                                CC Entry
                              </Button>
                            </Tooltip>
                          ) : (
                            <>
                              <Tooltip title="edit">
                                <EditIcon
                                  onClick={() => CCEntryFunction(row, "edit")}
                                />
                              </Tooltip>
                              <Tooltip title="View">
                                <ReportProblemIcon
                                  onClick={() =>
                                    CCEntryFunction(row, "Warning")
                                  }
                                />
                              </Tooltip>
                              <Tooltip title="delete">
                                <DeleteIcon onClick={() => DeleteCCdata(row)} />
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {policyList?.length < 1 ? (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      sx={{ textAlign: "center", border: "none" }}
                    >
                      <CloudOffIcon
                        sx={{ fontSize: "100px", color: "#c5c3c3" }}
                      />
                      <br />
                      <Typography sx={{ color: "#c5c3c3" }}>
                        Oops! No Data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {edit === "ccEntry" ? (
          <Drawer
            open={openViewCC}
            sx={{
              zIndex: 100,
            }}
            anchor="right"
            PaperProps={{
              sx: { width: { xs: "100%", sm: "100%" } },
            }}
          >
            <ViewCCentry
              edit={edit}
              selectedData={selectedData}
              queryParam={queryParam}
              setOpenViewCC={setOpenViewCC}
              setPolicyList={setPolicyList}
              setCount={setCount}
              setTotalPremium={setTotalPremium}
              filterBy={filterBy}
              loader={setOpenLoader}
            />
          </Drawer>
        ) : (
          <Dialog open={openViewCC}>
            <ViewCCentry
              edit={edit}
              selectedData={selectedData}
              setOpenViewCC={setOpenViewCC}
              setPolicyList={setPolicyList}
              filterBy={filterBy}
              loader={setOpenLoader}
            />
          </Dialog>
        )}
        <ConfirmBox
          open={openConfirmBox}
          title="Delete"
          content="Are you sure want to Delete"
          confirmButton="Delete"
          setOpenConfirmBox={setOpenConfirmBox}
          Function={DeleteFunction}
          icon={<DeleteIcon />}
          color="error"
        />
        <Loader open={openLoader} />
      </div>
    </>
  );
};
export default CCEntry;
